import { takeLatest, put, select, call, takeEvery, delay } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { pointUpdate, setSelectedMesh,  setLoadingConfig, setLoadingMeshes, setMesh, setLoadingTestRunOutput, extractionInfoGet, setLoadingExtractionInfo, setExtractionInfo, setLoadingCentralPoints, centralPointsSet, boundaryLinesSet, setLoadingChartData, initialChartDataSet, chartDataSet, setSelectedBoundaryOrForcing, dataLinkExtractionBuilderDataSet, dataLinkMeshGet, dataLinkExtractionBuilderDataGet, fastWaveConfigSet, uploadPointsUrlSet, uploadingPoints, loadingCoordinateSystems, coordinateSystemsSet, uploadDrawnGeometry, setProj4String, getProj4String, loadingExtractionBuilderData, setMeshExtent, pointLayerInitialDraw, centralPointsGet, setDrawing, setPointDecimals, getPointDecimals, setElevationName, setBoundaryCondition, setLoadingPreviousExtractions, setPreviousExtractions, setExistingPointNames } from '../actions/mapContent'
import { getCoordinateSystem, getCoordinateSystems, getProjectDataset, getProjectDatasets, getProjectSubprojects } from '../apis/metadataApi';
import { IGetDataset } from '../model/IGetDataset';
import { IAction } from '../actions/Action';
import { exportAndDownloadDataset } from '../actions/exportAndDownload';
import {  DATALINKJOBS, DATASETS, DRAWING, EXTRACTION_ARCHIVE_EXTENSION, EXTRACTION_ARCHIVE_FIXED_NAME_PART, EXTRACTION_INFO_FILE_EXTENSION, EXTRACTION_INFO_FIXED_NAME_PART, FORCINGS, OUTPUTFOLDER, WAVES } from '../shared/constants'
import { getDataLinkExtractionArchive, getDataLinkExtractionInfo, getFastwaveConfigFile, getFastwaveTestRunOutput, getNextFreeNumber, getValidExtractions, orderLayers } from '../helpers/fastwave';
import { getBoundaryLines, getCentralPoints, getChartData, getChartIntialData, getEvents, getMesh, postEvents, getTotalExplainedVarianceRatio, downloadSetupToProject, validateFastwaveConfig, transformPoints, getVtpForGISVectorDataset, IUploadedPoint } from '../apis/backendApi';
import {  getCoordSystems, getCurrentProj4String, getDataLinkExtractionBuilderData, getDataLinkProviders, getDrawing, getEPSGCode, getEventsSelector, getExistingPointNames, getExtractionPeriodEnd, getExtractionPeriodStart, getFastWaveConfig, getPoints, getProject, getSelectedBoundaryOrForcing, getSelectedEvents, getSelectedMesh, getUploadedPoints, getUploadPointsUrl } from '../reducers/state';
import { IGetProject } from '../model/IGetProject';
import { boundaryColor } from '../helpers/colors';
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import { IDataLinkProvider, IListItem } from '../reducers/mapContent';
import { Feature } from 'geojson';
import { IFastWaveConfig } from '../model/IFastWaveConfig';
import { getExtractionBuilderData, getDataLinkMeshStatus, uploadMeshToDLStorage, startExtraction, deleteMeshFromDLStorage } from '../apis/dataLinkApi';
import {  IMeshImportStatuses, IMeshStatus } from '../model/IGetDataLinkMeshStatus';
import { setDataLinkJobId } from '../actions/job';
import { dateTimeFormat } from '../helpers/fixTime';
import { IValidateConfig } from '../model/IValidateConfig';
import { addError } from '../actions/errors';
import IProjection from '../components/mike-projection-select/model/IProjection';
import { deleteOutputFolder } from '../actions/projectContent';
import { addLayer, hideLayerByNames, removeLayers, setLegendGroupLayersLoaded, updateLayer } from '../actions/legend';
import { backgroundLayers, MESH_LAYER_ID, meshAndPointsGroup, meshInputLayers } from '../reducers/legend';
import { AUTO_MESH_ID, ELEVATION, GEBCO_ID, MESH_BOUNDARY_CENTRAL_POINTS_LAYER_ID } from '../components/Viewer';
// import fetchMock from '../apis/mockData';
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { REPRESENTATION } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConstants';
import MikeVisualizerIO from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerIO';
import { IViewerBounds } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';
import { getNiceDataLinkJobName } from '../helpers/jobs';
import { getDecimalsForPointsFromWKT } from '../helpers/utils';
import { resetAutoMesh, setVtk } from '../actions/createMesh';
import { isEqual } from 'lodash';
import uniqid from 'uniqid'
import { intl } from '..';

export const POINT_NAME = 'Point';

export function* watchGetPointLayer() {
  yield takeLatest(ActionType.CONFIG_LAYER_GET, handleGetConfigLayer)  
  yield takeEvery(ActionType.MESH_UPLOADED, handleMeshUploaded) 
  yield takeEvery(ActionType.LOAD_MESH, handleGetMesh)
  yield takeEvery(ActionType.LOAD_POINTS, handleLoadPointsfromPlatform)
  yield takeEvery(ActionType.SET_SELECTED_MESH, handleSetSelectedMesh)
  yield takeEvery(ActionType.TESTRUN_OUTPUT_GET, handleGetTestRunOutput)
  yield takeEvery(ActionType.EXTRACTION_INFO_LAYER_GET, handleGetExtractionInfo)
  yield takeEvery(ActionType.BOUNDARYCONDITION_SET, handleBoundaryConditionsSet)
  yield takeEvery(ActionType.CENTRAL_POINTS_GET, handleGetCentralPoints)
  yield takeEvery(ActionType.SELECTED_BOUNDARY_OR_FORCING_SET, handleBoundaryOrForcingSelected)
  yield takeEvery(ActionType.CHART_DATA_GET, handleChartUpdate)
  yield takeLatest(ActionType.DATALINK_MESH_GET, handleGetDataLinkMesh)
  yield takeLatest(ActionType.DATALINK_EXTRACTION_BUILDER_DATA_GET, handleGetDataLinkExtractionBuilderData)
  yield takeLatest(ActionType.DATALINK_EXTRACT_DATA, handleExtractDataFromLinkDataStorage)
  yield takeEvery(ActionType.EVENTS_SAVE, handleSaveEvents)
  yield takeEvery(ActionType.SETUP_GET, handleGetSetup)
  yield takeEvery(ActionType.TRANSFORM_POINTS, handleTransformPoints)
  yield takeLatest(ActionType.GET_COORDINATE_SYSTEMS, handleGetCoords)
  yield takeEvery(ActionType.DRAWN_DATA_ADD, handleDrawnData)
  yield takeEvery(ActionType.GET_PROJ4STRING, handleGetProj4String)
  yield takeEvery(ActionType.SET_MESH, handleSetMeshVtk)
  yield takeEvery(ActionType.GET_POINT_DECIMALS, handleGetPointDecimals)
  yield takeEvery(ActionType.SET_PREVIOUS_EXTRACTION,handleSetPreviousExtraction)
  yield takeEvery(ActionType.GET_PREVIOUS_EXTRACTIONS,handleGetPreviousExtractions) 
  yield takeEvery( ActionType.LEGENDGROUP_GET_BACKGROUND_VTP, handleGetBackgroundVtp)
  yield takeLatest(ActionType.REPLACE_POINTS_WITH_TRANSFORMED_POINTS, handleReplaceExistingPoints)
  yield takeLatest(ActionType.RENAME_UPLOADED_POINTS, handleRenameUploadedPoint)
}

function* handleGetBackgroundVtp(action){
  const project: IGetProject | null = yield select(getProject);
  const projectId = project.id
  const epsg = yield select(getEPSGCode)
  const {datasetId, update} = action.data 
  if (project && epsg && datasetId){
    let dataset: IGetDataset;
    try{
      dataset = yield call(getProjectDataset, datasetId)
    }
    catch(error){
      yield put(addError("Failed to load dataset " + datasetId))      
    }     
    if (dataset){   
      if (update){
        yield put(updateLayer(backgroundLayers, {groupTitle: backgroundLayers, title: dataset.name,id: datasetId,visible: false,isTwoD: false,loaded: false, order: 1000, loading: true, canDelete: project.capabilities && project.capabilities.canDeleteContent}));
      }
      else{
        yield put(addLayer(backgroundLayers, {groupTitle: backgroundLayers, title: dataset.name,id: datasetId,visible: false,isTwoD: false,loaded: false, order: 1000, loading: true, canDelete: project.capabilities && project.capabilities.canDeleteContent}));
      }      
      let vtp;
      try{
        vtp = yield call(getVtpForGISVectorDataset,datasetId, epsg.toString()) 
      }
      catch(error){
        yield put(removeLayers(backgroundLayers, [datasetId]));
        yield put(addError("Failed to load " + dataset.name))
      }       
      if (vtp){
        yield put(setVtk(vtp, datasetId, dataset.name, projectId))
      }
    }      
  } 
}

function* handleGetPointDecimals(action){
  const wkt = action.data
  if (wkt){
    let decimals = 6
    try{
      decimals = yield call(getDecimalsForPointsFromWKT, wkt)
    }
    catch(error){
      console.log(error)
    }  
    yield put(setPointDecimals(decimals))
  }
}

function* handleSetMeshVtk(action){
  const vtk = action.data
  const { updateData, resetCameraToBounds } = MikeVisualizerLib;
  const {_parseVtkDataXml} = MikeVisualizerIO;
  const debugOn = localStorage.getItem('DEBUG_ON') && localStorage.getItem('DEBUG_ON') === 'true'
  const parsedVtpData = _parseVtkDataXml(vtk)  
  const bounds = parsedVtpData.getBounds() // xMin, xMax, yMin, yMax, zMin, zMax
  const meshExtent = [bounds[0], bounds[1], bounds[2], bounds[3], 0, 0] as IViewerBounds
  yield put(setMeshExtent(meshExtent))
  resetCameraToBounds(meshExtent)
  const pointData = parsedVtpData.getPointData();
  const dataArrays = pointData.getArrays();
  if (debugOn){
    const pointNames = dataArrays.map((da) => da.getName())
    const fieldData = parsedVtpData.getFieldData().getArrays();      
    const fieldNames = fieldData.map((da) => da.getName());
    const cellData = parsedVtpData.getCellData().getArrays();      
    const cellNames = cellData.map((da) => da.getName())
    console.log("field names: " + fieldNames, "point names: " + pointNames, "cell names: " + cellNames )
  }      
  const elevationName = dataArrays.find((da) => (da.getName() as string).toLowerCase().endsWith(ELEVATION))
  if (elevationName !== undefined){
    const points = parsedVtpData.getPoints()        
    for (let i = 0; i < points.getData().length; i++) {
      const point = points.getPoint(i)
      points.setPoint(i, point[0], point[1], 0)
    }
    updateData(parsedVtpData, MESH_LAYER_ID, [0,0.6431372549019608,0.9254901960784314, 1],[1,1,0, 1], {edgeVisibility: true, representation:2},2, true, {gradientPreset:"erdc_rainbow_bright", numberOfLegends: 10, numberOfSignificantDigits:3}, elevationName.getName(), true, false);  
    yield put(setElevationName(elevationName.getName())) 
  }
  else{   
    updateData(vtk, MESH_LAYER_ID, [1,1,1, 1],[1,1,1, 1], REPRESENTATION.WIREFRAME,4, false, null, null, true) 
  } 
  yield call(orderLayers);   
  yield put(pointLayerInitialDraw())
  yield put(setLegendGroupLayersLoaded(meshAndPointsGroup));
  yield put(centralPointsGet()) 
}

function* handleGetProj4String(action){
  const epsg = action.data  
  const proj4String = yield select(getCurrentProj4String)
  let wkt
  
  const coordinateSystems: Array<IProjection> = yield select(getCoordSystems)
  const cs = coordinateSystems.find((p: IProjection) => p.id === epsg)
  if (cs && cs.proj4String){
    if (cs.proj4String !== proj4String){
      wkt = cs.wkt
      yield put(setProj4String(cs.proj4String, epsg))
    }      
  }
  else{
    let coordinateSystem: IProjection
    try{
      coordinateSystem = yield call(getCoordinateSystem, epsg.toString())
    }
    catch(error){
      console.log(error)
    }
    
    if (coordinateSystem && coordinateSystem.proj4String !== proj4String){
      wkt = coordinateSystem.wkt
      yield put(setProj4String(coordinateSystem.proj4String, epsg))
    }
  } 
  if (wkt){
    yield put(getPointDecimals(wkt))
  } 
}

function* handleGetPreviousExtractions(action){  
  const id = action.data
  if (!id){
    return;
  }
  try {
    yield put(setLoadingPreviousExtractions())
    const datasets: Array<IGetDataset> = yield call(getProjectDatasets, id)
    if (datasets && datasets.length > 0) {
      const validExtractions = getValidExtractions(datasets);
      yield put(setPreviousExtractions(validExtractions))
    }
    else{
      yield put(setPreviousExtractions(Array<IGetDataset>()))
    }
  } catch (error) {
    yield put(setPreviousExtractions(Array<IGetDataset>()))
    console.log(error)
  } finally {
    yield put(setLoadingPreviousExtractions(false))
  }
}

function* handleGetCoords(){
  const coordinateSystems: Array<IProjection> = yield select(getCoordSystems) 
  if (coordinateSystems.length === 0){
    try {
      yield put(loadingCoordinateSystems())
      const response = yield call(getCoordinateSystems)
      if (response && response.data) {
        yield put(coordinateSystemsSet(response.data))
      }
    } catch (error) {
      console.log(error)
    } finally {
      yield put(loadingCoordinateSystems(false))
    }
  }
}

const getHighestPointId = (points) => {
  let id = 0;
  if (points.length > 0){
    const pointsWithPointIds = points.filter((p: any) => p.id && p.id.startsWith(POINT_NAME)).map((p: any) => p.id.replace(POINT_NAME, "")).sort()
    const highestPointId  = pointsWithPointIds[pointsWithPointIds.length - 1] 
    try{
      id = parseInt(highestPointId)
    }
    catch (error){
      console.log(error)
    } 
  }
  return id
}

const createFeatures = (uploadedPoints: Array<IUploadedPoint>, id: number) => {
  const features =  uploadedPoints.map((row: IUploadedPoint, index: number) => {
    const pointName = row.name ? row.name : POINT_NAME + (index + 1 + id)
    return {
    type: "Feature",
    id: pointName,
    properties:{
      id: pointName
    },
    geometry: {
      type: "Point",
      coordinates: [row.x, row.y]
    }}}
  )
  return features; 
}

const getHighestNewPointId = (existingPointNames: Array<string>, name: string) => {  
  const trials = [1,2,3,4,5,6,7,8,9,10]
  let renamed;
  trials.forEach((trial: number) => {
    if (!renamed){
      const newName = name + " (" + trial + ")"
      if (!existingPointNames.includes(newName)){
        renamed =  newName
      }
    }    
  }) 
  return renamed ? renamed : name + " (" + uniqid.time() + ")"
}

function* handleRenameUploadedPoint(){
  const duplicatedNames = yield select(getExistingPointNames)
  const uploadedPoints = yield select(getUploadedPoints)
  const points = yield select(getPoints)
  const pointsWithUniqueNames = uploadedPoints.filter((p: IUploadedPoint) => !duplicatedNames.includes(p.name))
  const pointsWithDuplicatedNames = uploadedPoints.filter((p: IUploadedPoint) => duplicatedNames.includes(p.name))
  let pointsToBeNamed = []
  pointsWithDuplicatedNames.forEach((p: IUploadedPoint) => {
    const name = p.name;
    const pointsWithSameName = pointsWithDuplicatedNames.filter((p: IUploadedPoint) => p.name === name)
    const renamedPoints = pointsWithSameName.map((p: IUploadedPoint) => {
      return {...p, name: getHighestNewPointId(duplicatedNames, p.name)}
    })
    pointsToBeNamed = pointsToBeNamed.concat(renamedPoints)
  })
  const id = getHighestPointId(pointsWithUniqueNames)   
  const renamedPointFeatures =  createFeatures(pointsToBeNamed, id) 
  const unrenamedPointFeatures =  createFeatures(pointsWithUniqueNames, id)  
  const mergedPoints = points.concat(renamedPointFeatures, unrenamedPointFeatures)
  yield put(pointUpdate(mergedPoints))
  yield put(uploadingPoints(false))
  yield put(setExistingPointNames([],[]))
}

function* handleReplaceExistingPoints(){  
  const uploadedPoints = yield select(getUploadedPoints)
  const uploadedPointNames = uploadedPoints.map((p: IUploadedPoint) => p.name)  
  const points = yield select(getPoints)
  const reducedPoints = points.filter((p: any) => !uploadedPointNames.includes(p.id))
  const id = getHighestPointId(reducedPoints)   
  const features = createFeatures(uploadedPoints, id)  
  const mergedPoints = reducedPoints.concat(features)
  yield put(pointUpdate(mergedPoints))
  yield put(uploadingPoints(false))
  yield put(setExistingPointNames([],[]))
}

function* handleTransformPoints(action){
  const meshDataset: IGetDataset | null = yield select(getSelectedMesh)
  const xyzFileBlobUrl = yield select(getUploadPointsUrl)
  const srid = action.data
  if (meshDataset && xyzFileBlobUrl && srid){   
    yield put(uploadingPoints(true))
    yield delay(2000) 
    const points = yield select(getPoints)
    const id = getHighestPointId(points)       
    const url = encodeURIComponent(xyzFileBlobUrl)
    let uploadedPoints
    try{
      const response = yield call(transformPoints, url, srid, meshDataset.id )
      if (response && response.length > 0){
        uploadedPoints = response        
      }
    }
    catch (error){
      yield put(addError(intl.formatMessage({id: 'points.uploadError'})))
      yield put(uploadingPoints(false))
    }
    finally{     
      yield put(uploadPointsUrlSet(""))      
    }
    if (uploadedPoints){
      const names = uploadedPoints.map((row: IUploadedPoint) => row.name)
      const existingPointsWithSameName = points.filter((p: any) => names.includes(p.id))
      if (existingPointsWithSameName.length === 0){
        const features = createFeatures(uploadedPoints, id)
        const mergedPoints = points.concat(features)
        yield put(pointUpdate(mergedPoints))
        yield put(uploadingPoints(false))
      }
      else{
        const existingNames: Array<string> = points.map((p: any) => p.id)    
        yield put(setExistingPointNames(existingNames, uploadedPoints))
      }
    }    
  }
}

function* handleGetSetup(action){
  const project: IGetProject | null = yield select(getProject) 
  if (project){
    const projectId = project.id
    const {setup, download} = action.data
    let id = "";
    try{
      const datasets = yield call(getProjectDatasets, projectId)    
      const setupDataset = datasets.find((ds: IGetDataset) => ds.name === setup)
      if (setupDataset === undefined){
        id = yield call(downloadSetupToProject, projectId, setup)
      }
      else{
        id = setupDataset.id
      }
    }
    catch(error){
      console.log(error)
    }

    if (id && download){
      yield put(exportAndDownloadDataset(setup,id,
        {importData: {name: setup, reader: "FileReader",writer: "FileWriter"}}, DATASETS.SETUP, true))        
    }
  }  
}

function* handleExtractDataFromLinkDataStorage(){  
  const project: IGetProject | null = yield select(getProject) 
  const config: IFastWaveConfig = yield select(getFastWaveConfig)
  const extractionBuilderData = yield select(getDataLinkExtractionBuilderData)
  const startDate =  yield select(getExtractionPeriodStart)
  const endDate =  yield select(getExtractionPeriodEnd)
  
  let configIsValid = false

  if (!config.output_points || config.output_points.length === 0){
    yield put(addError("Please add at least one point of interest")); 
  }

  if (!config.mesh_file || !config.mesh_file.name){
    yield put(addError("Please select a mesh")); 
  }

  try{
    const validateConfig: IValidateConfig = yield call(validateFastwaveConfig, project.id)
    configIsValid = validateConfig && validateConfig.meshExists && validateConfig.meshSelected && validateConfig.pointsAreWithinMesh && validateConfig.pointsExists  
    if (!configIsValid){
      if (validateConfig) {
        if (!validateConfig.meshSelected){
          yield put(addError("Please select a mesh")); 
        }
        if (!validateConfig.meshExists){
          yield put(addError("Mesh does not exist in your project")); 
        }
        if (!validateConfig.pointsExists){
          yield put(addError("Please add at least one point of interest")); 
        }
        if (!validateConfig.pointsAreWithinMesh){
          yield put(addError("Please go back to previous step and delete points of interest outside your mesh")); 
        }
      }      
    }  
  }  
  catch (error){
    yield put(addError("Your configuration is invalid.")); 
  }

  
  if (configIsValid && extractionBuilderData && project && config && config.mesh_file && config.mesh_file.name && startDate && endDate){

    const providers = extractionBuilderData.providers 
    
    if (providers){
      const dataLinkProviders = yield select(getDataLinkProviders)
      const forcings = dataLinkProviders.filter((dp: IDataLinkProvider) => dp.selected && FORCINGS.includes(dp.id))
      const selectedForcingIds = forcings.map((dp: IDataLinkProvider) => dp.selected.name)  
      const boundaryConditions = dataLinkProviders.filter((dp: IDataLinkProvider) => dp.selected && dp.id === WAVES)
      const selectedBoundaryConditionName = boundaryConditions.map((dp: IDataLinkProvider) => dp.selected.name)      
  
      const fc = extractionBuilderData.forcings.forcingsCollapsed.forcingsCollapsedItems
      const forcingsCollapsedItems = fc.map((f) => { return {...f, providers: f.providers.map((p) => {return {...p, selected: selectedForcingIds.includes(p.name)}})}})
      const boundariesCollapsed = extractionBuilderData.boundaries.boundariesCollapsed
      const boundaryItemsWithReducedNodes = boundariesCollapsed.boundaryCollapsedItems.map((b) => {return {...b, horRes: b.horRes > 3 ? 3 : b.horRes}})
      const boundaryProviders = boundariesCollapsed.providersCollapsed.map((p) => {return {...p, selected: selectedBoundaryConditionName.includes(p.name)}})
      const body = 
      {
        providers: providers,
        boundaries: {
          isExpanded: false,
          boundariesExpanded: extractionBuilderData.boundaries.boundariesExpanded, 
          boundariesCollapsed: {
              boundaryCollapsedItems: boundaryItemsWithReducedNodes,
              providersCollapsed: boundaryProviders
          }
        },
        initConditions: extractionBuilderData.initConditions,
        forcings: {
          isExpanded: false,
          forcingsExpanded: extractionBuilderData.forcings.forcingsExpanded,
          forcingsCollapsed: {
            forcingsCollapsedItems: forcingsCollapsedItems
          }            
        }
      }  
      const start = startDate + ".000Z"
      const end = endDate + ".000Z"
      try{
        yield put(deleteOutputFolder())
        const updatedConfig = {...config }
        if (updatedConfig.data_link_output_info_file){              
          delete updatedConfig.data_link_output_info_file
        } 
        if (updatedConfig.data_link_output_file){              
          delete updatedConfig.data_link_output_file
        } 
        yield put(fastWaveConfigSet(updatedConfig, true))
        const dataExtractionJobId = yield call(startExtraction, project.id, config.mesh_file.name, start, end, body)
        yield put(setDataLinkJobId(DATALINKJOBS.DATAEXTRACTION, dataExtractionJobId, dateTimeFormat(Date.now()), getNiceDataLinkJobName(DATALINKJOBS.DATAEXTRACTION), project.id))    
      }
      catch(error){
        console.log(error)
      }        
    }    
  }
}

function* handleGetDataLinkMesh(action){
  const {meshName, projectId} = action.data 
  if (projectId && meshName){
    let meshIsUploadedToDLStorage = false
    let meshStatus;
    try{
      meshStatus = yield call(getDataLinkMeshStatus, projectId)    
    }
    catch(error){
      console.log(error)
    }
    
    if (meshStatus && meshStatus.length > 0){
      const dataLinkMesh = meshStatus.find((status: IMeshStatus) => status.meshName === meshName)
      meshIsUploadedToDLStorage = dataLinkMesh !== undefined && dataLinkMesh.hasBoundariesExtracted     
    }
    if (!meshIsUploadedToDLStorage){
      let meshImportStatuses;
      try{
        meshImportStatuses = yield call(uploadMeshToDLStorage, projectId, [meshName]);  
      }
      catch(error){
        console.log(error)
      }      
      if (meshImportStatuses){
        const importStatuses: IMeshImportStatuses = JSON.parse(meshImportStatuses)        
        if (importStatuses && importStatuses.MeshImportStatuses && importStatuses.MeshImportStatuses.length > 0){
          const firstStatus = importStatuses.MeshImportStatuses[0]
          const meshImportJobId = firstStatus.BoundaryExtractionJobGuid
          yield put(setDataLinkJobId(DATALINKJOBS.BOUNDARYEXTRACTION, meshImportJobId,  dateTimeFormat(Date.now()), getNiceDataLinkJobName(DATALINKJOBS.BOUNDARYEXTRACTION), projectId))
        }       
      }
    }
    else{
      yield put(dataLinkExtractionBuilderDataGet())
    }
  }
}

function* handleGetDataLinkExtractionBuilderData(){
  const project: IGetProject | null = yield select(getProject)
  const config: IFastWaveConfig = yield select(getFastWaveConfig)
  if (project && config && config.mesh_file && config.mesh_file.name){
    try{
      yield put(loadingExtractionBuilderData())
      const extractionBuilderData = yield call(getExtractionBuilderData, project.id, config.mesh_file.name)
      yield put(dataLinkExtractionBuilderDataSet(extractionBuilderData))
    }
    catch (error){
      console.log(error)
    }
    finally{
      yield put(loadingExtractionBuilderData(false))
    }
  }
}

function* handleChartUpdate(action){
  const project: IGetProject | null = yield select(getProject)
  const selectedBoundaryOrForcing = yield select(getSelectedBoundaryOrForcing)
  const {xItem, yItem} = action.data
  if (selectedBoundaryOrForcing && selectedBoundaryOrForcing.id && xItem && yItem && xItem.id && yItem.id){    
    try{
      yield put(setLoadingChartData())
      const chartData = yield call(getChartData, project.id, selectedBoundaryOrForcing.id, xItem.id, yItem.id)
      yield put(chartDataSet(chartData))
    }
    catch (error){
      console.log(error)
    }
    finally{
      yield put(setLoadingChartData(false))
    }
  }
}

/* const addMockData = (d) => {

  const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

  // Generate numbers range 0..4
  const years = range(2019, 2060, 1);
  let mockData = [...d]
  years.forEach((year: number) => {
   
    const mock = d.map((x => {return {...x, Time: x.Time.replace("2018-", year.toString() + "-")}} ))
    mockData = mockData.concat(mock)
  })
  return mockData;
} */

function* handleBoundaryOrForcingSelected(action){
  const id = action.data.id
  const project: IGetProject | null = yield select(getProject)
  if (id){    
    try{
      yield put(setLoadingChartData())
      // const initialChartData = yield call(fetchMock)
      const initialChartData = yield call(getChartIntialData, project.id, id)
      
      yield put(initialChartDataSet(initialChartData))
    }
    catch (error){
      console.log(error)
    }
    finally{
      yield put(setLoadingChartData(false))
    }
  }
}

function* handleBoundaryConditionsSet(action){
  const {boundaryConditionsDataset, readExtractionPeriodFromInfoFile} = action.data
  if (readExtractionPeriodFromInfoFile && boundaryConditionsDataset){    
    const zipFileName = boundaryConditionsDataset.name
    if (zipFileName){
      const fullFileNameParts = zipFileName.split(".")
      if (fullFileNameParts.length > 0){
        const nameParts = fullFileNameParts[0].split(" ")
        if (nameParts.length === 2){
          const extractionInfoFileName = DATASETS.EXTRACTION_INFO + " " + nameParts[1] + "." + EXTRACTION_INFO_FILE_EXTENSION      
          yield put(extractionInfoGet(extractionInfoFileName,zipFileName))  // To return the extraction period, we need to read the info file related to the extraction zip folder 
        }
      } 
    }     
  }  
}

function* handleSetSelectedMesh(action){ 
  const {selectedDataset: selectedMesh, projectId} =  action.data
  const project: IGetProject | null = yield select(getProject)
  if (project && project.id && project.id !== projectId ){
    return;
  }
  const fastWaveConfig: IFastWaveConfig = yield select(getFastWaveConfig)
  
  if (!selectedMesh){
    yield put(setProj4String("", null))
  }  
   
  if (project && selectedMesh){  
    try{
      if (selectedMesh.spatialInformation && selectedMesh.spatialInformation.srid){
        yield put(getProj4String(selectedMesh.spatialInformation.srid))
      }
      else{
        yield put(setProj4String("", null))
      }      
      const updatedConfig = {...fastWaveConfig, mesh_file: {dataset_id: selectedMesh.id, name: selectedMesh.name} }
      yield put(fastWaveConfigSet(updatedConfig, true)) 
      yield put(setLoadingMeshes())
      yield put(dataLinkMeshGet(selectedMesh.name, project.id))
     
      const meshResponse = yield call(getMesh, selectedMesh.id)
      const projectId = project.id 
      if (meshResponse){
        yield put(setMesh(meshResponse)) 
        yield put(setLoadingMeshes(false))
        yield put(hideLayerByNames(meshInputLayers, [GEBCO_ID, AUTO_MESH_ID]))
      }   
      try{        
        const response = yield call(getBoundaryLines, projectId, selectedMesh.id)
        if (response){         
          const boundaryLines = JSON.parse(response) 
          yield put(boundaryLinesSet(boundaryLines && boundaryLines.features ? boundaryLines.features : new Array<Feature>()))
        }        
      }
      catch (error){
        console.log(error)
      }
      /* if (selectedMesh.spatialInformation && selectedMesh.spatialInformation.srid && selectedMesh.spatialInformation.srid === 4326){
        try{       
          const vtk = yield call(getGWM, project.id)
          if (vtk){   
            const { updateData } = MikeVisualizerLib;
            updateData(vtk, GWM_ID, [0,0.6431372549019608,0.9254901960784314, 1],[0,0.6431372549019608,0.9254901960784314, 1], {edgeVisibility: false, representation:1},3, false, undefined, null, true);
            yield put(addLayer(meshAndPointsGroup,  {title: "GWM", id: GWM_ID, visible: true, isTwoD: false, loaded: false, order: -1}));      
            yield put(setGWM(vtk))
          }        
        }
        catch (error){
          console.log(error)
        }
      } */
      /* try{       
        const response = yield call(getIsMeshWithinGWM, projectId)
        if (response){         
          yield put(setMeshIsWithinGWMErrors(response, true))
        }        
      }
      catch (error){
        yield put(setMeshIsWithinGWMErrors(new Array<string>(), false))
      } */
    }
     
    finally{
      yield put(setLoadingMeshes(false))
    }
  }
}

function* handleMeshUploaded(action) {
  const datasetId = action.data
  const project : IGetProject | null = yield select(getProject); 
  if (datasetId){   
    try{
      const data: IGetDataset = yield call(getProjectDataset, datasetId)
      if (data && data.spatialInformation && data.spatialInformation.srid){
        const meshName = data.name      
        try{
          const meshStatus = yield call(getDataLinkMeshStatus, project.id)   
          if (meshStatus && meshStatus.length > 0){
            const dataLinkMesh = meshStatus.find((status: IMeshStatus) => status.meshName === meshName)           
            if (dataLinkMesh){
              yield call(deleteMeshFromDLStorage, project.id, meshName)
            }   
          } 
        }
        catch(error){
          console.log(error)
        }  

        const epsg = data.spatialInformation.srid
        const coordinateSystems: Array<IProjection> = yield select(getCoordSystems)
        const cs = coordinateSystems.find((p: IProjection) => p.id === epsg)
        let wkt
        if (cs && cs.proj4String){
          wkt = cs.wkt
          yield put(setProj4String(cs.proj4String, epsg))
        }
        else{
          let coordinateSystem: IProjection
          try{
             coordinateSystem = yield call(getCoordinateSystem, epsg.toString())
          }
          catch(error){
            console.log(error)
          }
          if (coordinateSystem){
            wkt = coordinateSystem.wkt          
            yield put(setProj4String(coordinateSystem.proj4String, epsg))
          }          
        } 
        if (wkt){
          yield put(getPointDecimals(wkt)) 
        }          
      }      
      yield put(setSelectedMesh(data, project.id))
    }
    catch(error){
      console.log(error)
    }    
  }
}

function* handleDrawnData(action){
  const drawing = yield select(getDrawing)
  const autoMeshNeedsToBeRest = [DRAWING.MESHOUTLINE, DRAWING.AREAOFINTEREST, DRAWING.OWN_SHORELINE].includes(drawing)
  if (autoMeshNeedsToBeRest){
    yield put(resetAutoMesh())
    yield put(deleteOutputFolder())
  }
  const features = action.data
  switch (drawing) {
    case DRAWING.POINT:{
      const points = yield select(getPoints)
      yield put(pointUpdate([...points, {...action.data[0], id: 'Point' + getNextFreeNumber(points) }]))
      break;
    }
    case DRAWING.MESHOUTLINE:
      yield put(setDrawing(DRAWING.NONE))
      yield put(uploadDrawnGeometry(DRAWING.MESHOUTLINE, {
        type: "FeatureCollection",
        features: features}))
      break;  
    case DRAWING.AREAOFINTEREST:
      yield put(setDrawing(DRAWING.NONE))
      yield put(uploadDrawnGeometry(DRAWING.AREAOFINTEREST, {
        type: "FeatureCollection",
        features: features}))
      break; 
    case DRAWING.OWN_SHORELINE:
      yield put(setDrawing(DRAWING.NONE))
      yield put(uploadDrawnGeometry(DRAWING.OWN_SHORELINE, {
        type: "FeatureCollection",
        features: features}))
      break;  
    default:
        return;
  }
}

function* handleLoadPointsfromPlatform(action: IAction<IGetDataset | null>){
  const dataset = action.data
  if (dataset){
    const format = dataset.datasetFormat
    if (format === "GeoJSON" || format === "ESRI Shapefile"){
  
      const importData = format === "ESRI Shapefile" ? 
      {
        name: dataset.name,
        reader: "ShpReader", 
        writer: "GeoJsonWriter",  
      } 
      : 
      {
        name: dataset.name,
        reader: "FileReader",
        writer: "FileWriter"
      } 
      yield put(exportAndDownloadDataset(dataset.name,dataset.id, {importData: importData},DATASETS.POINTS ))
    }
  }
}

function* handleSaveEvents(action){
  const projectId = action.data
  if (projectId){
    const selectedEvents = yield select(getSelectedEvents)
    const events = yield select(getEventsSelector)    
    if (selectedEvents && selectedEvents.length > 0 && events.length !== selectedEvents.length){
      const sortedEvents = events.sort();
      const sortedSelEvents = selectedEvents.sort();
      if (!isEqual(sortedEvents, sortedSelEvents)){        
        try{
          yield call(postEvents, projectId, sortedSelEvents)        
        }
        catch (error){
          console.log(error)
        }  
      }      
    }
  }  
}

function* handleGetCentralPoints(action){
  const getBoundaryColor = (id: string, coloredboundaryAndForcings: Array<IListItem>) => {
    const coloredBoundary = coloredboundaryAndForcings.find(cb => cb.id === id) as any
    return coloredBoundary === undefined ? MIKE_COLORS.BRANDBLUE_DEFAULT : coloredBoundary.color
  }

  const getSourceExtraction = (sourceFileName: string) => {
    let source = ""
    if (sourceFileName){
      const parts = sourceFileName.split("/")
      if (parts.length > 0){
        source = parts[0]
      }
    }
    return source
  }
  const project: IGetProject | null = yield select(getProject)
  
  const projectId = project && project.id ? project.id : action && action.data ? action.data : ""

  
  if (projectId){   

    yield put(setLoadingCentralPoints()) 
    try{
    
      const centralPoints = yield call(getCentralPoints, projectId)
      const centralBoundaryPoints = centralPoints && centralPoints.boundaries ? centralPoints.boundaries.map((p: any) => {return {id: p.id, displayName: p.displayName, color: boundaryColor(p.id), source: getSourceExtraction(p.sourceFileName)} as IListItem}) : new Array<IListItem>()
      const boundaryAndForcings = centralPoints && centralPoints.forcings && centralBoundaryPoints.concat(centralPoints.forcings.map((p: any) => {return {id: p.id, displayName: p.displayName, color: MIKE_COLORS.BRANDBLUE_DEFAULT, source: getSourceExtraction(p.sourceFileName)} as IListItem}))
      
      const coloredCentralBoundaryPoints = centralPoints && centralPoints.boundaries ? centralPoints.boundaries.map((p: any) => {return {type: "Feature",
        geometry: {
          type: "Point",
          coordinates: p.coordinates
        },
        id: p.id,
        color: getBoundaryColor(p.id, boundaryAndForcings),
        properties: { id: p.id}} as Feature}
      ) : []
      
      let events = new Array<string>()
      
      try{
          const response = yield call(getEvents, projectId)
          if (response && response.length > 0){
            events = response
          }
      }
      catch (error){
        console.log(error)
      }

      let totalExplainedVarianceRatio = 0
      
      try{
          const response = yield call(getTotalExplainedVarianceRatio, projectId)
          if (response){
            totalExplainedVarianceRatio = response
          }
      }
      catch (error){
        console.log(error)
      }
      
      
      yield put(centralPointsSet(coloredCentralBoundaryPoints, boundaryAndForcings, events, totalExplainedVarianceRatio))
      if (coloredCentralBoundaryPoints.length > 0){
        yield put(addLayer(meshAndPointsGroup,  { groupTitle: meshAndPointsGroup, title: "Central points", id: MESH_BOUNDARY_CENTRAL_POINTS_LAYER_ID, visible: true, isTwoD: true, loaded: false, order: 2}))
      }
      if (boundaryAndForcings.length > 0){
        yield put(setSelectedBoundaryOrForcing(boundaryAndForcings[0]))
      }
      
    }
    catch (error){
      console.log(error)
    } 
    finally{
      yield put(setLoadingCentralPoints(false)) 
    } 
   
  }
}

function* handleSetPreviousExtraction(action){
  const extractionArchiveDataset = action.data
  const project: IGetProject | null = yield select(getProject)
  if (extractionArchiveDataset){
    let datasets = new Array<IGetDataset>()
    try{
      datasets = yield call(getProjectDatasets, project.id)
    }
    catch(error){
      console.log(error)
    }
    const infoName = extractionArchiveDataset.name.replace(EXTRACTION_ARCHIVE_FIXED_NAME_PART, EXTRACTION_INFO_FIXED_NAME_PART)
    const infoFileName = infoName.replace(EXTRACTION_ARCHIVE_EXTENSION, EXTRACTION_INFO_FILE_EXTENSION)
    const extractionInfoDataset: IGetDataset | undefined = getDataLinkExtractionInfo(datasets, infoFileName) 
    if (extractionInfoDataset){
      yield put(setBoundaryCondition(extractionArchiveDataset))
      yield put(setExtractionInfo(extractionInfoDataset))     
      try{
        yield put(exportAndDownloadDataset(extractionInfoDataset.name,extractionInfoDataset.id,
          {importData: {name: extractionInfoDataset.name, reader: "FileReader",writer: "FileWriter"}}, DATASETS.EXTRACTION_INFO, false, true))        
      }
      catch (error){
        console.log(error)
      }    
    }   
  }
}

function* handleGetExtractionInfo(action){  
  const { infoFileName, archiveName } = action.data
  const project: IGetProject | null = yield select(getProject)
  if (project){
    const projectId = project.id   
    yield put(setLoadingExtractionInfo())    
    let datasets = new Array<IGetDataset>()
    try{
      datasets = yield call(getProjectDatasets, projectId)
    }
    catch(error){
      console.log(error)
    }
    
    const extractionInfoDataset: IGetDataset | undefined = getDataLinkExtractionInfo(datasets, infoFileName) 
    const extractionArchiveDataset: IGetDataset | undefined = getDataLinkExtractionArchive(datasets, archiveName) 
    if (extractionInfoDataset && extractionArchiveDataset){
      yield put(setExtractionInfo(extractionInfoDataset))     
      try{
        yield put(exportAndDownloadDataset(extractionInfoDataset.name,extractionInfoDataset.id,
          {importData: {name: extractionInfoDataset.name, reader: "FileReader",writer: "FileWriter"}}, DATASETS.EXTRACTION_INFO))        
      }
      catch (error){
        console.log(error)
      }         
    }
  }  
}



function* handleGetTestRunOutput(){
  yield put(setLoadingTestRunOutput())  

  const project: IGetProject | null = yield select(getProject)
  if (project){
    const projectId = project.id   
    try{
      const folders = yield call(getProjectSubprojects, projectId)
      const outputFolder = folders.find((f: IGetProject) => f.name.toLowerCase() === OUTPUTFOLDER)
      if (outputFolder && outputFolder.id){
        const datasets = yield call(getProjectDatasets, outputFolder.id)
        const testRunDataset: IGetDataset | undefined = getFastwaveTestRunOutput(datasets) 
        if (testRunDataset !== undefined){
          yield put(exportAndDownloadDataset(testRunDataset.name,testRunDataset.id,
          {
            importData: 
            {
              name: testRunDataset.name,
              reader: "FileReader",
              writer: "FileWriter"
            }
          },
          DATASETS.TESTRUNOUTPUT 
          ))
        }
      }      
    }
    finally{
      yield put(setLoadingTestRunOutput(false))
    }
  }

  else{
    yield put(setLoadingTestRunOutput(false))
  }
  
}

function* handleGetMesh(action: IAction<string>){
  yield put(setLoadingMeshes())
  const datasetId = action.data
  let dataset
  try{
    dataset = yield call(getProjectDataset, datasetId)
  }
  catch(error){
    console.log(error)
  }  
 
  if (dataset){
    yield put(exportAndDownloadDataset(dataset.name,datasetId, 
     {
       importData: 
       {
         name: dataset.name,
         reader: "FileReader",
         writer: "FileWriter"
       }
     },
     DATASETS.MESHBOUNDARY 
     ))
   }
   else{
     yield put(setLoadingMeshes(false))
   }
}

function* handleGetConfigLayer(action: IAction<string>){
  yield put(setLoadingConfig())
  const projectId = action.data   
  let datasets = new Array<IGetDataset>()
  try{
    datasets = yield call(getProjectDatasets, projectId)
  }
  catch(error){
    console.log(error)
  }  
  const fastwaveConfigDataset: IGetDataset | undefined = getFastwaveConfigFile(datasets) 
  
  if (fastwaveConfigDataset !== undefined){
    const {name, id } = fastwaveConfigDataset
    if (name && id){
      yield put(exportAndDownloadDataset(name,id,
      {
        importData: 
        {
          name: name,
          reader: "FileReader",
          writer: "FileWriter"
        }
      },
      DATASETS.CONFIG 
      ))
    }
    else{
      yield put(setLoadingConfig(false))
    }
  }
  else{
    yield put(fastWaveConfigSet({}))
    yield put(setLoadingConfig(false))
  }
    
}
