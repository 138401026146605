import React, { useCallback, useMemo, useState } from 'react';
import DataTable from '../TableComponents/DataTable';
import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete'
import { ReactComponent as TopUnfold } from '@mike/mike-shared-frontend/media/icons/TopUnfold'
import { GEBCO, GEBCO_BATHYMETRY, ICreateMeshDataset, IEntity, UPLOADING_BATHYMETRY } from '../../reducers/createMesh';
import { iconStyle, tableCellButtonStyle, tableRowButtonStyle } from '../TableComponents/renderers';
import MikeDialog from '../DialogComponents/MikeDialog';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVtkItems } from '../../actions/createMesh';
import { dateTimeRender } from '../Project/projectContentColumns';
import { setBathymetryDatasets } from '../../actions/mapContent';
import { DATASETS } from '../../shared/constants';
import { useIntl } from 'react-intl';
import { IState } from '../../reducers';
import { checkMarkStyle, circleStyle, LOADING, MISSING, VALID } from '../MeshInputTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as Checkmark } from "@mike/mike-shared-frontend/media/icons/Checkmark"
import { iconSecondaryStyle } from '../EditPointsForm/iconStyles';
import Button from '@material-ui/core/Button';
import { css } from 'emotion';
import theme from '../../shared/mikeSharedTheme';
import LongText from '../renders/LongText';
import ContextHelp from '../ContextHelp';

interface IProps { 
  data: Array<ICreateMeshDataset >;
  canUpdateContent: boolean;
}

interface IDeletion {
 message: string;
 itemsToDelete: Array<ICreateMeshDataset>;
}

const PRIORITY = 'priority'

const priorityStyle = 
  css`
   padding-right: ${theme.spacing(1)}px;
  `

const gebcoPriorityStyle = 
  css`
   width:  ${theme.spacing(1)}px;
  `
const nameStyle = 
  css`
   display: flex;
   align-items: center;
   justify-content: space-between;
   width:  ${theme.spacing(21)}px;
  `
    
const BathymetryTable = (props: IProps) => {
  const { 
    data,   
    canUpdateContent,
  } = props;  
  
  const dispatch = useDispatch();
  const intl = useIntl();

  const {loadingGebco, loadingBathymetry} = useSelector(
    (state: IState) => state.createMesh
  ); 

  const loading = useMemo(() => {
    return loadingBathymetry || loadingGebco
  }, [loadingBathymetry, loadingGebco])

  const entities = useMemo(() => {
    const gebcoDataset = data && data.find((d: ICreateMeshDataset) => d.name === GEBCO_BATHYMETRY);
    const meshInput = Array<IEntity>();
    if (gebcoDataset){ 
      meshInput.push({ ...gebcoDataset,
        status: VALID,
        canUpdate: true,
        hasProperties: false,
        entityType: GEBCO,
        key: gebcoDataset.id
      })
    }
    else{
      meshInput.push({ 
        name: GEBCO_BATHYMETRY,
        status: loadingGebco ? LOADING : MISSING,
        canUpdate: true,
        hasProperties: false,
        entityType: GEBCO,
        priority: -1,
        key: "-1"
      })
    }
    const otherBathymetries = data && data.filter((d: ICreateMeshDataset) => d.name !== GEBCO_BATHYMETRY);
    otherBathymetries.forEach((d: ICreateMeshDataset) => {
      meshInput.push({ ...d,
        status: VALID,
        canUpdate: true,
        hasProperties: false,
        entityType: d.name,
        key: d.id
      })
    })
    if (loadingBathymetry){
      meshInput.push({
        status: LOADING,
        canUpdate: true,
        hasProperties: false,
        name: UPLOADING_BATHYMETRY,
        entityType: UPLOADING_BATHYMETRY,
        priority: -1,
        key: "0"
      })
    }
    const sortedBathys = meshInput.sort((a: ICreateMeshDataset, b: ICreateMeshDataset) => {return a.priority - b.priority}) 
    return sortedBathys;
  }, [data, loadingBathymetry, loadingGebco])

  const [confirmDeletion, setConfirmDeletion] = useState<IDeletion | null>(null);

  const handleDataDragged = useCallback((data: Array<ICreateMeshDataset>) => {    
    if (loading){
      return;
    } 
    let priority = 0;     
    const updatedPriority = data.map((d: ICreateMeshDataset, index: number) => {
      if (d.priority !== -1){
        priority = priority +1;
        return {...d, priority: priority}
      }
      else{
        return d
      }     
    })
    const gebco = updatedPriority.find((d: ICreateMeshDataset) => d.name === GEBCO_BATHYMETRY && d.priority !== -1)
    if (gebco !== undefined && gebco.priority !== data.length){
      return;
    }
    dispatch(setBathymetryDatasets(updatedPriority, true))
  }, [dispatch, loading])  

  const renderDeleteItem = useCallback((_value: any, row: ICreateMeshDataset) => {   
    const deleteActionClicked = (row: ICreateMeshDataset) => {  
      setConfirmDeletion({message: intl.formatMessage({id: 'warnings.deleteItem'}), itemsToDelete: [row]})
    }
    
    return row && ![GEBCO_BATHYMETRY, UPLOADING_BATHYMETRY].includes(row.name) ? (
      <Button  
        variant="text"
        color="secondary"
        onClick={() => deleteActionClicked(row)}
      >
        <Delete className={iconSecondaryStyle(!canUpdateContent)} width={24} height={24} viewBox={"0 0 40 40"}/>   
        {intl.formatMessage({id: 'autoMesh.delete'})}   
      </Button>
    ) : null
  }, [canUpdateContent, intl])

  const renderMoveItem = useCallback((_value: boolean, row: IEntity) => {   
    const handleDataMoved = (data: Array<ICreateMeshDataset>) => {
      const updatedPriority = data.map((d: ICreateMeshDataset, index: number) => {return {...d, priority: index + 1}})     
      dispatch(setBathymetryDatasets(updatedPriority, true))
    }
  
    const moveItem = (row: IEntity) => {  
      if (loading){
        return;
      }
      if (entities && entities.length > 1){
        const currentPriority = row.priority;
        const targetPriority = currentPriority === 1 ? 2 : currentPriority - 1
        const targetIndex = entities.findIndex((r: IEntity) => r.priority === targetPriority)            
        const updatedRows = entities.filter((r: IEntity) =>  r.priority !== currentPriority)
        updatedRows.splice(targetIndex, 0, row) 
        const gebco = updatedRows.find((d: IEntity) => d.name === GEBCO_BATHYMETRY)
        if (gebco !== undefined && gebco.priority !== entities.length){
          return;
        }
        handleDataMoved(updatedRows)           
      }    
    }
    return entities && entities.length > 1 ? (
      <div className={tableRowButtonStyle(!canUpdateContent)} onClick={() => moveItem(row)}>
        <div className={priorityStyle}>{row && row.priority && row.priority}</div>
        {row && ![GEBCO_BATHYMETRY, UPLOADING_BATHYMETRY].includes(row.name) ?
        <TopUnfold className={iconStyle(!canUpdateContent)} /> : <div className={gebcoPriorityStyle}/>}        
      </div>
    ) : null
  }, [entities, canUpdateContent, dispatch, loading])

  const renderStatus = useCallback((_value: boolean, row: IEntity) => {
    const isLoading = (entityType: string) => {
      let loading = false
      switch (entityType){
        case GEBCO: {
          loading = loadingGebco
          break;
        } 
        case UPLOADING_BATHYMETRY: {
          loading = loadingBathymetry
          break;
        }       
      }
      return loading
    }

    return row && row.id ? (
      <div className={tableRowButtonStyle()}><div className={circleStyle}><Checkmark className={checkMarkStyle()} width={24} height={24} viewBox={"0 0 40 40"}/></div></div>
    ) : (
      isLoading(row.entityType) ? <div className={tableRowButtonStyle()}><CircularProgress size={20}/> </div>: <div className={tableRowButtonStyle()}>{"NA"}</div>
    )
  }, [loadingGebco, loadingBathymetry]) 

  const itemNameRender = (value: string) => {
    return <div className={nameStyle}><LongText text={value} />{value === GEBCO_BATHYMETRY && <ContextHelp primary helpTexts={[intl.formatMessage({id: 'autoMesh.gebco_hint'})]}/>}</div>;
   }

  const columns = [
    {
      field: 'name',
      label: 'Name',
      render: itemNameRender,
    }, 
    {
      field: 'updated',
      label: 'Edited on',
      render: dateTimeRender   
    },
    {
      field: 'status',
      label: 'Status',
      render: renderStatus
    }, 
    {
      field: PRIORITY,
      label: 'Priority',
      render: renderMoveItem,
      info: [intl.formatMessage({id: 'autoMesh.priority_hint'})]      
    },
    {
      field: 'delete',
      label: '',
      render: renderDeleteItem, 
      className: tableCellButtonStyle    
    },    
  ]

  const handleOnCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setConfirmDeletion(null);
    }
  }

  const deleteData = useCallback(() => {
    confirmDeletion && confirmDeletion.itemsToDelete && dispatch(deleteVtkItems(confirmDeletion.itemsToDelete, DATASETS.BATHYMETRY))
    setConfirmDeletion(null);
  }, [confirmDeletion, dispatch])

  const notSortableColumns = columns.map(({field}) => field)

  return (
    <>
     <MikeDialog 
        open={confirmDeletion !== null} 
        onCancel={handleOnCancel} 
        onOk={deleteData}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: 'warnings.resetMesh'})}
        message={confirmDeletion ? confirmDeletion.message : ''}    
      />  
    
      <DataTable        
        loading={false}
        columns={columns}
        idField="key"   
        data={entities}
        selectedRows={new Array<ICreateMeshDataset>()}
        onCellClick={() => {}}      
        onSelectionChange={() => {}}
        onHandleRequestSort={() => {}}
        orderBy={PRIORITY}
        order={'asc'}   
        selectable={false}
        topOffset={0}   
        onDragEnd={handleDataDragged} 
        notSortableColumns={notSortableColumns}
      />
    </>
  );
};

export default BathymetryTable;
